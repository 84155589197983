<template>
  <div
    eagle-video-player-kernel="videojs"
    :style="containerStyle"
  >
    <video
      v-show="domReady"
      class="video-js"
      :id="elementId"
      controls
      :width="width"
      :height="height"
    ></video>
  </div>
</template>

<script lang="babel" type="text/babel">
import uuidV4 from 'uuid/v4'
import videoPlayerKernel from 'components/videoPlayer/playerKernel/videoPlayerKernel'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import 'videojs-logo'
import 'videojs-logo/dist/videojs-logo.css'
import 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'

export default {
  mixins: [videoPlayerKernel],
  data: () => ({
    domReady: false,
  }),
  mounted() {
    this.setup()
  },
  methods: {
    async setup() {
      this.domReady = false
      await this.$nextTick()
      this.domReady = true
      await this.$nextTick()
      const $playerContainer = $(this.$el).find('.video-js')[0]
      const self = this
      videojs($playerContainer, this.playerOptions, async function onPlayerReady() {
        self.player = this
        await self.$nextTick()
        self.onPlayerReady()
      })
    },
    async onPlayerReady() {
      // 參考videojs-logo plugin: https://github.com/tapioca24/videojs-logo
      this.player.logo({
        image: require('@/assets/logo.png'),
        width: 60,
        height: 60,
        hideOnReady: false,
        fadeDelay: null,
        padding: 10,
        opacity: 0.7,
      })

      if(this.videoType === this.$videoConstants.TYPE_STATIC_VIDEO) {
        this.player.src({
          src: this.staticVideoUrl,
        })
      }

      else if(this.videoType === this.$videoConstants.TYPE_STREAMING_VOD) {
        this.player.src({
          src: this.streamingUrl,
          type: 'application/x-mpegURL',
          withCredentials: true,
        })
        this.player.hlsQualitySelector = hlsQualitySelector
        this.player.hlsQualitySelector({
          displayCurrentQuality: true,
        })
      }
    },
  },
  computed: {
    videoType() {
      return this.video.type
    },
    env() {
      return this.$store.getters['base/eagleEnv']
    },
    streamingUrl() {
      return this.video.streaming_url
    },
    staticVideoBaseUrl() {
      return this.env.staticVideoBaseUrl
    },
    staticVideoUrl() {
      return `${this.staticVideoBaseUrl}/${this.video.static_video_path}`
    },
    elementId() {
      return `video-js-${uuidV4()}`
    },
    playerOptions() {
      return {
        controls: true,
        preload: 'auto',
        poster: this.thumbnail,
        // html5: {
        //   hls: {
        //     withCredentials: true,
        //   }
        // },
        // hls: {
        //   withCredentials: true,
        // }
        html5: {
          vhs: {
            overrideNative: true,
            withCredentials: true,
          }
        }
      }
    },
    thumbnail() {
      const videoPhoto = this.$helper.getPhotoUrl(this.video.photo)
      if(videoPhoto) return videoPhoto
      return require('@/assets/default-image.png')
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
